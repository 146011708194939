@import '../../styles/mixins.scss';

.airdrop {
  overflow-x: hidden;

  .container {
    position: relative;
  }
  &__body {
    padding-top: 346px;

    @include sm {
      padding-top: 430px;
    }
    @media (min-width: 1513px) {
      padding-top: 500px;
    }
    @include xl {
      padding-top: 600px;
    }
  }
}

.content-airdrop {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border-radius: 26px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: contain;

  @include sm {
    padding: 36px 36px 52px 42px;
    background-image: url('../../assets/airdrop/bgBmain.png');
    background-position: center;
    background-size: cover;
  }

  @include md {
    padding: 51px 31px 31px 36px;
    flex-direction: row;
  }

  @media (min-width: 1513px) {
    padding: 97px 31px 31px 36px;
  }

  @include xl {
    padding: 69px 43px 55px 47px;
    flex-direction: row;
  }
}
.content-airdrop-left {
  max-width: 730px;
  &__title {
    line-height: 118%;
  }
  &__subtitle {
    margin-bottom: 16px;
    line-height: 118%;
    @include sm {
      margin-bottom: 28px;
    }
    @include md {
      max-width: 560px;
    }
  }
}

.btn-airdrop {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 139px;
  flex-shrink: 0;
  border-radius: 10px;
  background: var(--dark-accent, #006c9a);

  border: none;
}

.airdrop-bg {
  position: absolute;
  top: 0;
  left: -26%;

  z-index: 50;

  width: 629px;
  height: 311px;

  background-image: url('../../assets/airdrop/social.png');
  background-position: top center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;

  @media (min-width: 475px) {
    left: 0;
  }

  @include sm {
    width: 948px;
    height: 469px;
  }

  @include md {
    top: 2%;
    left: 18%;
  }
  @include lg {
    top: 0;
    left: 23%;
  }
  @media (min-width: 1513px) {
    width: 1228px;
    height: 607px;
    top: -2%;
    left: 15%;
  }
  @include xl {
    width: 1294px;
    height: 640px;
    top: 5%;
    left: 22%;
  }
  @include xxl {
    top: 5%;
    left: 25%;
  }
}
