@import "../mixins";

.info-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  @include md {
    flex-wrap: nowrap;
    flex-direction: row;
    gap: 18px;
  }
  @include lg {
    gap: 40px;
  }
  @include xl {
    gap: 70px;
  }
}
.info-tools-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  @include md {
    flex-direction: column;
    position: fixed;
  }
  @include lg {
  }
}
@media (max-width: 1279px) {
  .info-tools-container > :last-child {
    order: -1;
  }
}
.sidebar-container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  min-width: 100%;
  align-self: self-start;
  padding: 16px 24px;
  gap: 24px;
  background-color: white;
  border-radius: 0 0 26px 26px;
  @include md {
    min-width: fit-content;
    border-radius: 26px;
  }
}

.page__container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-self: self-start;
  padding: 24px 20px;
  background-color: white;
  border-radius: 26px;
  @include sm {
    padding: 24px 38px;
  }
  @include md {
    width: 57%;
    margin-left: 28%;
  }
}
.buttons-info {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    gap: 15px;
    background: #ffffff;
    border-radius: 26px 26px 0 0;

    @include sm {
      flex-direction: row;
      align-items: center;
    }
    @include md {
      flex-direction: column;
      height: fit-content;
      align-self: self-start;
      min-width: 245px;
      @include md {
        border-radius: 0;
        background: none;
        padding: 40px 0 0 0;
      }
    }
    @include lg {
      min-width: 300px;
    }
    @media (min-width: 1512px) {
      min-width: 330px;
    }
  }
}
.tips__container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-self: self-start;
  gap: 24px;
}
.tips__content-frame {
  height: 300px;
  background: #d2e2e7;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 32px 0;
}
.tips__title1 {
  margin-top: 20px;
  margin-bottom: 30px;
  font-weight: bold;
}
