@import '../../styles/mixins.scss';

.video-modal-container {
   position: fixed;
   z-index: 101;
   top: 0;
   left: 0;
   height: 100%;
   width: 100%;

   background: rgba(0, 0, 0, 0.75);
}
.video-modal-iframe {
   position: absolute;
   z-index: 1000;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   height: 60%;
   width: 70%;

   border-radius: 24px;
   border: 1px solid white;

   @include xs {
      height: 70%;
   }

   @include sm {
      height: 80%;
      width: 80%;
   }
}
