@import "../../mixins";


.sidebar-item {
  max-width: 100%;
  padding: 10px 10px;
  margin-bottom: 10px;
  cursor: pointer;
  @include sm {
    margin-bottom: 16px;
  }
  @include md {
    margin-bottom: 0;
    width: 280px;
  }
  @include lg {
    width: 300px;
  }
  @include xl {
    width: 330px;
  }
  &:hover {
    font-weight: bold;
    background: #e6faff;
    border-radius: 8px;
    padding-left: 12px;
  }
  &.active {
    font-weight: bold;
    background: #e6faff;
    border-radius: 8px;
    padding-left: 12px;
  }
}