@import '../../styles/mixins.scss';

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 68px;
  padding: 0 10px;

  background-color: rgb(255, 255, 255);

  @include xs {
    padding-right: 10px;
    padding-left: 10px;
  }

  @include sm {
    padding-right: 20px;
    padding-left: 20px;
  }

  @include xl {
    height: 84px;
    padding-right: 40px;
    padding-left: 40px;
  }

  @include xxl {
    padding-right: 64px;
    padding-left: 64px;
  }

  &__container {
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    width: 106px;
    height: 16px;
    cursor: pointer;

    @include sm {
      width: 162px;
      height: 25px;
    }

    @include xxl {
      width: 196px;
      height: 30px;
    }
  }
}

.nav {
  display: flex;
  width: 100%;
  height: 100%;
  margin-right: 20px;
  flex-direction: row;
  gap: 12px;
  justify-content: right;

  @include sm {
    width: 100%;
    gap: 12px;
    justify-content: center;
  }
  @include lg {
    gap: 33px;
  }
  @include xl {
    gap: 44px;
  }
  @include xxl {
    gap: 56px;
  }

  &__wrapper {
    display: none;
    @include md {
      display: flex;
    }
  }

  &__link {
    display: flex;
    padding: 4px 10px;
    background-color: rgb(255, 255, 255);
    font-weight: 400;
    text-align: center;
    justify-content: center;
    list-style-type: none;
    cursor: pointer;
    align-items: center;
    transition: text-shadow 200ms;


    &:hover {
      text-shadow: 0 0 .60px #333, 0 0 .60px #333;
    }
  }

  &__burger {
    display: block;
    border: none;
    background-color: transparent;
    cursor: pointer;

    @include md {
      display: none;
    }
  }

  &__burger-icon {
    display: flex;
    width: 100%;
  }
}

.navbar {
  display: flex;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99999;
  width: 100%;
  height: calc(100vh - 65px);
  margin-top: 60px;
  background-color: rgb(255, 255, 255);

  flex-direction: column;
  align-items: center;

  &__link {
    margin-top: 35px;
    font-size: 16px;
    @include sm {
      font-size: 32px;
    }
  }
}

.nav__login {
  display: inline-flex;
  width: 80px;
  padding: 6px 16px;
  border: 0;
  border-radius: 50px;
  background-color: rgba(203, 244, 255, 0);
  color: rgb(0, 0, 0);
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;

  @include xs {
    margin-left: 26px;
    background-color: rgb(203, 244, 255);
  }
  @include sm {
    margin-left: 81px;
  }
  &:hover{
    opacity: .85;
  }

  span {
    display: none;
    @include xs {
      display: block;
    }
  }

  svg {
    display: block;
    @include xs {
      display: none;
    }
  }
}
