@import "../../mixins";

.buttons {
  &__btn {
    display: flex;
    width: 100%;
    padding: 11px 18px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
  }

  &__btn-submit {
    justify-content: center;
    background: #000000;
    color: #ffffff;
    text-transform: uppercase;
  }

  &__btn-submit-group{
    display: flex;
    gap: 7px;
  }
}

