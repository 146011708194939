@import '../../styles/mixins.scss';
.capex {
  &__container {
    max-width: 1920px;
    margin: 0 auto 26px;
    padding: 0 10px;
    @include sm {
      margin-bottom: 42px;
      padding: 0 20px;
    }
    @include xl {
      padding: 0 40px;
    }
    @include xxl {
      padding: 0 64px;
    }
  }
  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 14px;
    margin-top: 110px;
    @include sm {
      gap: 0;
    }
    @include md {
      gap: 24px;
    }
    @include xl {
      position: relative;
    }
  }
}

.main-capex {
  position: relative;
  height: 100vh;
  padding: 24px 10px;
  border-radius: 16px;
  background: url('../../assets/capex/capsula_new_sx.png') 50% 100% / cover no-repeat;
  @include xs {
    padding: 35px 15px;
    border-radius: 26px;
    background-blend-mode: overlay, normal;
  }

  @media (min-width: 600px) {
    background: url('../../assets/capex/capsula_new_xl.png') 50% 100% / cover no-repeat;
  }

  @include md {
    padding: 32px 36px;
  }
  @include lg {
    height: 120vh;
  }
  @include xl {
    height: 100vh;
    padding: 64px 51px;
  }
  .capsule {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .tablet {
    display: block;
    @include sm {
      display: none;
    }
  }
  .desktop {
    display: none;
    @include sm {
      display: block;
    }
  }
  &__subtitle {
    max-width: 90%;
    @include xs {
      max-width: 100%;
    }
    @include sm {
      max-width: 800px;
    }
    @include md {
      max-width: 60%;
    }
    @include xl {
      max-width: 60%;
    }
  }
  &_statistics {
    display: flex;
    max-width: 90%;
    margin-top: 46px;
    flex-wrap: wrap;
    @include md {
      max-width: 60%;
    }
    .stat {
      width: 35%;
      margin-right: 35px;
      margin-bottom: 10px;
      @include xs {
        width: 27%;
        margin-right: 40px;
        margin-bottom: 20px;
      }
      @include sm {
        width: 20%;
      }
      .count {
        font-weight: 600;
        font-size: 26px;
        line-height: 110%;
        white-space: nowrap;
        @include sm {
          font-size: 42px;
          line-height: 120%;
        }
        span {
          font-weight: 600;
          font-size: 16px;
          line-height: 100%;
        }
      }
      .title {
        width: 100%;

        @include lg {
          width: 70%;
        }
      }
    }
  }
}

.capex-form {
  @include sm {
    position: absolute;
    right: 4%;
    left: 4%;
    bottom: 3%;

    border-radius: 16px;
    background: #ffffff;
  }
  @include md {
    display: flex;
    justify-content: flex-end;
    left: auto;
  }
  @include lg {
    position: absolute;
    right: 2%;
    bottom: 3%;

    background: #ffffff;
  }
}
