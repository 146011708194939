@import '../../styles/mixins.scss';

.solutions {
  &__body {
    display: flex;
    padding: 18px 0;
    flex-direction: column;
    gap: 14px;
    @include sm {
      gap: 20px;
    }
    @include md {
      flex-direction: row;
      gap: 40px;
    }
    @include xl {
      gap: 45px;
    }
  }

    &__hero {
      @include sm {
        height: 60vh;
      }
      @include md {
        height: auto;
        flex-basis: 45%;
      }
      @include xxl {
        height: auto;
        flex-basis: 55%;
      }

      &-img {
        width: 100%;
        height: 100%;
        border-radius: 19px;
        object-fit: cover;

        @include sm {
          object-position: 70% 10%;
        }
        @include md {
          object-position: 90% 10%;
        }
      }
    }
  }

    .main-solutions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 50px;
      @include sm {
        gap: 60px;
        flex-basis: 65%;
      }
      @include lg {
        gap: 80px;
      }

      &__title {
        margin-bottom: 3px;
        @include lg {
          margin-bottom: 45px;
        }
      }

      &__text {
        margin-bottom: 16px;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
        @media screen and (min-width: 630px) {
          width: 80%;
        }
      }

      &__text-info {
        margin-top: 10px;
      }
    }




