@import '../../styles/mixins.scss';

.transportation {
  &__body {
    display: flex;
    height: calc(100vh - 80px);
    min-height: 462px;
    flex-direction: column;
    gap: 14px;

    @include sm {
      gap: 0;
    }
    @include md {
      gap: 24px;
    }
    @include lg {
      position: relative;
    }
  }
}

.main-transportation {
  height: calc(100vh - 15%);
  padding: 24px 10px;
  border-radius: 16px;
  background: url('../../assets/transportation/main-bg-390.jpg') 50% 100% / cover no-repeat;
  @include xs {
    padding: 35px 15px;
  }

  @include sm {
    padding: 42px 36px;
    border-radius: 26px;

    background: linear-gradient(0deg, rgba(255, 219, 94, 0.09), rgba(255, 219, 94, 0.09)),
      url('../../assets/transportation/main-bg-834.jpg') 50% / cover no-repeat;
    background-blend-mode: overlay, normal;
  }
  @include md {
    padding: 32px 36px;
    background: linear-gradient(0deg, rgba(255, 219, 94, 0.09), rgba(255, 219, 94, 0.09)),
      url('../../assets/transportation/main-bg-1280.jpg') 50% / cover no-repeat;
    background-blend-mode: overlay, normal;
  }
  @include lg {
    height: 100vh;
  }
  @include xl {
    padding: 64px 51px;
  }

  &__subtitle {
    @include sm {
      max-width: 70%;
    }
  }
}