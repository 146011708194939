@import '../../../../mixins.scss';

.support-submitBtn {
  cursor: pointer;
  width: 100%;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;

  background: #000000;
  border-radius: 10px;

  @include sm {
    width: 184px;
  }
}
