// Reset styles
@import "./styles/reset.scss";

// Breakpoints mixins
@import "./styles/mixins.scss";

// Fonts
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;&display=swap');
//@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap");
// Global Typography
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
a,
button {
  font-family: 'Poppins', sans-serif;
  text-align: left;
  letter-spacing: 0;
}

// Global Styles
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html,
body {
  min-width: 320px;
  background: rgb(255, 255, 255);
  color: rgb(0, 0, 0);
  line-height: 1;
  font-weight: 400;
  // Media Queries
  .container {
    max-width: 1920px;
    margin: 0 auto;
    padding: 10px 10px;

    @include xs {
      padding: 10px 20px;
    }
    @include md {
      padding: 10px 30px;
    }
    @include lg {
      padding: 10px 40px;
    }
    @include xxl {
      padding: 10px 64px;
    }
  }
  // Desktop
  h1,
  h2,
  h3,
  h4 {
    line-height: 110%;
  }

  @media screen and (min-width: 320px) {
    h1 {
      font-weight: 400;
      font-size: 28px;
    }
    
    h2 {
      font-weight: 400;
      font-size: 26px;
    }

    h3 {
      font-weight: 500;
      font-size: 24px;
    }

    h4 {
      font-weight: 600;
      font-size: 18px;
    }
  }

  @media screen and (min-width: 834px) {
    h1 {
      font-size: 42px;
    }

    h2 {
      font-size: 42px;
    }

    h3 {
      font-size: 36px;
    }

    h4 {
      font-size: 18px;
    }
  }

  @media screen and (min-width: 1440px) {
    h1 {
      font-size: 52px;
    }

    h2 {
      font-size: 52px;
    }

    h3 {
      font-size: 36px;
    }

    h4 {
      font-size: 24px;
    }
  }
}
.bodyUppercase{
  font-size: 15px;
  text-transform: uppercase;
  @media screen and (min-width: 834px) {
    font-size: 20px;
  }
}
//обсудить
.menuRegular{
  font-size: 16px;
}

.regularDefault{
  font-size: 14px;
  @media screen and (min-width: 834px) {
      font-size: 16px;
  }
}

.regularMedium{
  font-size: 12px;
  @media screen and (min-width: 834px) {
    font-size: 14px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 16px;
  }
}

.regularSmall{
  font-size: 11px;
  @media screen and (min-width: 834px) {
    font-size: 12px;
  }
  @media screen and (min-width: 1440px) {
    font-size: 14px;
  }
}
.regularDescription {
  font-size: 10px;
  @media screen and (min-width: 834px) {
    font-size: 12px;
  }
}
.semibold {
  font-weight: 600;
}
.medium {
  font-weight: 500;
}
.underline {
  text-decoration: underline;
}
.uppercase {
  text-transform: uppercase;
}



.container__pages {
  display: grid;
  //flex-direction: column;
  margin: 60px 0 0;
  padding: 0;
  grid-gap: 80px;
  @include sm {
    grid-gap: 120px;
  }
  @include lg {
    grid-gap: 140px;
  }
}
