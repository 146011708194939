@import '../../../mixins.scss';

.supportForm {
  &__container {
    position: relative;
    max-width: 748px;
    width: 100%;
    background: #f8fdff;
  }
  &__container--loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    border: '1px solid black';
  }

  &__infoBlock {
    display: flex;
    justify-content: space-between;
    margin-bottom: 14px;
    margin-top: 20px;
    margin-left: 38px;
  }

  &__infoBlock-right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 6px;

    @include xs {
      gap: 8px;
    }
  }

  &__infoBlock-number {
    font-size: 10px;
    line-height: 110%;
    text-transform: uppercase;
    color: #7d99a0;
    color: #000000;
  }

  &__infoBlock-title {
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    text-transform: uppercase;
    color: #000000;
  }

  &__infoBlock-created {
    font-size: 10px;
    line-height: 110%;
    color: #7d99a0;
  }

  &__textarea {
    max-width: 710px;
    margin-top: 25px;
    margin-bottom: 16px;

    @include sm {
      margin-left: 38px;
    }

    textarea {
      resize: none;
      width: 100%;
      min-height: 142px;
      border: none;
      padding: 8px 16px;
      background: #fff;
      font-size: 16px;
      line-height: 100%;
      border-radius: 16px;

      color: #000000;

      &::placeholder {
        font-size: 16px;
        line-height: 100%;

        color: #7d99a0;
      }
    }
  }
  &__bottom {
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include sm {
      flex-direction: row;
      gap: 0;
      justify-content: space-between;
    }
  }

  &__close {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 4px;

    @include sm {
      margin-left: 38px;
      max-height: 24px;
      margin-top: 10px;

      transition: opacity 0.2s ease-in-out;
      &:hover {
        opacity: 0.6;
      }
    }

    div {
      margin-top: 2px;
      font-weight: 400;
      font-size: 14px;
      text-transform: uppercase;
      color: #000000;
    }
  }
  &__close-svg {
    max-height: 24px;
  }

  &__btnContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include sm {
      flex-direction: row;
      justify-content: right;
    }
  }
}

//
.supportForm {
  &__chat {
    max-width: 100%;
    max-height: 380px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 25px;
    padding-right: 10px;

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 50px;
    }
  }
}

.message-supportForm {
  display: flex;
  gap: 6px;
  &__body {
    width: 100%;
    padding: 8px;
    background: #e6faff;
    border-radius: 16px;
  }
  &__body--owner {
    // background: transparent;
    background: #e6faff;
  }

  &__name {
    margin-top: 4px;
    margin-bottom: 12px;
    font-weight: 600;
    font-size: 11px;
    line-height: 110%;
    text-transform: uppercase;
    color: #000000;

    @include sm {
      font-size: 14px;
    }
  }

  &__text {
    margin-bottom: 14px;
    font-size: 14px;
    line-height: 120%;
    color: #000000;

    @include sm {
      font-size: 16px;
    }
  }

  &__date {
    font-size: 10px;
    line-height: 110%;
    color: #7d99a0;

    @include sm {
      font-size: 12px;
    }
  }
}
