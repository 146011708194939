@import "../../styles/mixins.scss";

.gallery {
  overflow-x: hidden;

  &__content {
    display: flex;
    flex-direction: column-reverse;
    gap: 18px;

    @media (min-width: 1024px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      gap: 0;
    }
  }

  &__left {
    @media (min-width: 1024px) {
      width: 40%;
    }

    @include lg {
      max-width: 562px;
    }
  }

  &__right {
    @media (min-width: 1024px) {
      width: 57%;
    }

    @include xl {
      width: 62%;
    }
  }

  &__title {
    margin-bottom: 90px;

    @media (min-width: 600px) {
      max-width: 534px;
    }

    @include md {
      max-width: 490px;
    }
  }

  &__subtitle {
    font-weight: 600;
    margin-bottom: 29px;

    @media (min-width: 600px) {
      max-width: none;
    }
  }
}

.form-gallery {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 28px;

    @include sm {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__input {
    width: 100%;
    padding: 1px 6px;
    border: none;
    border-bottom: 1px solid #000000;
    background-color: transparent;
    font-size: 16px;
    outline: none;
    text-transform: uppercase;

    &::placeholder {
      color: #000000;
      text-transform: uppercase;
    }

    @media (min-width: 600px) {
      max-width: 342px;
    }
  }

  &__btn {
    @include sm {
      width: 292px;
    }
    button {
      width: 100%;

      @media (min-width: 600px) {
        max-width: 342px;
      }

      @include sm {
        width: 100%;
      }

      @media (min-width: 1024px) {
        max-width: 184px;
      }
    }
  }
}
.right-gallery {
  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 4.5px;

    @media (min-width: 600px) {
      justify-content: center;
      gap: 11px;
    }
  }

  &__item {
    max-width: 49%;
    @include xl {
      max-width: 505px;
      max-height: 364px;
    }
    img {
      border-radius: 24px;
    }
  }
}
