@import "../../../styles/mixins";

.tabs {
  &__container {
    display: flex;
    flex-direction: column;
    padding: 0 24px 34px 24px;

    @media (min-width: 480px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 0 24px 42px 24px;
    }
    @include sm {
      padding: 0 24px 42px 24px;
    }
    .tabs__container-caption {
      font-weight: bold;
      margin-bottom: 12px;
    }
    .tabs__container-pagination {
      display: none;
      @media (min-width: 480px) {
        display: flex;
        font-weight: 600;
        color: #7d99a0;
        font-size: 14px;
      }
      @media (min-width: 1512px) {
        font-size: 16px;
      }
    }
  }
  &__links {
    display: none;
    @media (min-width: 480px) {
      display: flex;
      flex-direction: row;
      gap: 42px;
    }
    @include lg {
      gap: 60px;
    }
    a {
      color: #7d99a0;
      text-decoration: none;
    }
    a.active {
      color: black;
      text-decoration: underline;
    }
  }

  &__content {
  }
}
