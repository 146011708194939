@import '../../../mixins.scss';

.ticketModal {
  &__wrapper {
    z-index: 50;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    width: 100vw;
    height: 100vh;
  }

  &__container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    background: #e6faffb7;
  }

  &__body {
    position: relative;
    max-width: 360px;
    width: 100%;
    padding: 24px;
    padding-bottom: 38px;
    background: #ffffff;
    border-radius: 24px;

    @media (min-width: 480px) {
      max-width: 420px;
    }
    @include sm {
      max-width: 560px;
    }
    @include lg {
      max-width: 640px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 112%;
    color: #000000;
    margin-top: 20px;
    margin-bottom: 44px;
  }
  &__close {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__textarea {
    width: 100%;
    height: 142px;
    padding: 12px 16px;
    margin-bottom: 36px;

    background: #ffffff;
    border: 1px solid #000000;
    border-radius: 16px;
    font-size: 14px;
    line-height: 120%;
    color: #000000;

    &::placeholder {
      font-size: 14px;
      line-height: 120%;
      color: #7d99a0;
    }
  }

  &__btnContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include sm {
      flex-direction: row;
      justify-content: right;
    }
  }
}
