@import "../../mixins";

.buttons {
  &__btn {
    display: flex;
    width: 100%;
    padding: 11px 18px;
    border-radius: 10px;
    cursor: pointer;
    border: none;
  }
  &__btn-tickets {
    justify-content: space-between;
    background: #e6faff;
    text-transform: uppercase;

    &-group {
      display: flex;
      gap: 7px;
    }
  }
}
