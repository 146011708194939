@import '../../../mixins.scss';

.tickets {
  &__container {
    max-width: 623px;
    width: 100%;
    @include sm {
      max-width: 716px;
    }
    @include md {
      max-width: 1172px;
    }
    @include lg {
      max-width: 1200px;
    }
  }

  &__top-container {
    display: flex;
    flex-direction: column;
    gap: 22px;
    margin-bottom: 26px;

    @include sm {
      flex-direction: row;
      gap: 28px;
      margin-bottom: 40px;
    }
    @include sm {
      gap: 0px;
      justify-content: space-between;
      margin-bottom: 30px;
    }
  }

  &__top-addBtn {
    cursor: pointer;
    display: flex;
    gap: 6px;
    justify-content: center;
    align-items: center;

    border-radius: 10px;
    background: #000000;
    padding: 7.5px 0;

    span {
      max-height: 24px;
    }

    @include sm {
      flex: 0 0 47.5%;
    }
    @include md {
      flex: 0 0 265px;
      margin-left: 39px;
    }
    @include lg {
      margin-left: 20px;
    }
  }
  &__top-addBtn-text {
    font-size: 16px;
    line-height: 110%;
    text-transform: uppercase;
    color: #ffffff;
  }

  &__top-searchInput-container {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 12px 3px 7px 0px;
    background: transparent;
    border-bottom: 1px solid #000000;

    span {
      max-height: 24px;
    }
    @include sm {
      flex: 0 0 47.5%;
    }
    @include md {
      flex: 0 0 51%;
      margin-right: 52px;
    }
  }

  &__top-searchInput {
    width: 95%;
    border: none;
    color: #000000;
    background: transparent;
    font-size: 16px;
    line-height: 110%;
    text-transform: uppercase;
    padding: 3px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      font-size: 16px;
      line-height: 110%;
      text-transform: uppercase;
      color: #7d99a0;
    }
  }

  &__body {
    padding: 32px 16px;
    background: #ffffff;
    border-radius: 26px;

    @include sm {
      padding: 48px 16px;
    }
  }

  &__body-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    @include sm {
      padding: 0px 44px;
      margin-bottom: 26px;
    }
  }

  &__filter {
    position: relative;
    display: flex;
    align-items: center;
    gap: 16px;

    &-body {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 12px;

      @include sm {
        transition: opacity 0.2s ease-in-out;

        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
  &__filter-text {
    margin-top: 1px;
    font-size: 14px;
    line-height: 110%;
    text-transform: uppercase;
    color: #000000;
  }
  &__filter-list {
    display: none;
    position: absolute;
    left: 110%;
    justify-content: center;
    gap: 30px;

    padding: 16px;
    background: #ffffff;
    border: 0.5px solid #000000;
    border-radius: 16px;

    @media (min-width: 600px) {
      display: flex;
    }
  }
  &__filter-item {
    display: flex;
    align-items: center;
    gap: 8px;

    input {
      cursor: pointer;
      width: 22px;
      height: 22px;
      border: 1px solid #000000;
      border-radius: 4px;
    }
    span {
      white-space: nowrap;
      font-size: 14px;
      line-height: 110%;
      text-transform: uppercase;
      color: #000000;
    }
  }

  &__edit {
    cursor: pointer;
    font-size: 14px;
    line-height: 110%;
    text-transform: uppercase;
    color: #7d99a0;

    @include md {
      margin-right: 17px;
    }
    @include lg {
      margin-right: 21px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-height: 480px;
    overflow-y: auto;
    padding-right: 10px !important;

    @include sm {
      gap: 8px;
      max-height: 400px;
    }

    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 50px;
    }
  }
}

.item-tickets {
  width: 100%;
  cursor: pointer;
  padding: 14.5px 9px 18px 16px;
  @include xs {
    padding-bottom: 28px;
  }
  @include sm {
    padding: 5px 8px;
  }
  background-color: white;
  border-bottom: 1px solid #d2e2e7;

  transition: background 0.1s ease-in-out, border-radius 0.1s ease-in-out,
    border-bottom-color 0.1s ease-in-out;
  &:hover {
    background: #d7ffdd;
    border-radius: 8px;
    border-bottom-color: #e6faff;
  }
  &-active {
    border-radius: 8px;
    background: #e6faff;
    border-bottom-color: #e6faff;
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;

    @include sm {
      flex-direction: row;
      align-items: center;
      padding: 9px 0;
    }
  }

  &__checkModal {
    cursor: pointer;
    position: absolute;

    top: 0;
    right: 28px;

    @include sm {
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__status {
    display: flex;
    align-items: center;
    gap: 10px;

    margin-bottom: 26px;

    @include sm {
      margin-bottom: 0;
      flex: 0 0 25%;
      margin-right: 15px;
    }
  }

  &__status-icon {
    max-height: 24px;
  }

  &__status-text {
    font-size: 12px;
    line-height: 110%;
    color: #000000;
    border-radius: 90px;
    padding: 6px 7.5px;
  }

  &__title {
    margin-bottom: 25px;

    h4 {
      font-weight: 400;
      font-size: 16px;
      line-height: 110%;
      text-transform: uppercase;
    }

    @include sm {
      margin-bottom: 0;
      flex: 0 0 37%;
    }
    @include md {
      flex: 0 0 32%;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 20px;

    @include sm {
      flex-direction: column;
      gap: 11px;
      align-items: baseline;
      flex: 0 0 15%;
      margin-right: 77px;
    }
    @include md {
      flex-direction: row;
      gap: 142px;
      align-items: center;
      flex: 0 0 24%;
      margin-right: 120px;
    }
  }

  &__number {
    font-size: 12px;
    line-height: 110%;
  }

  &__created {
    font-size: 12px;
    line-height: 110%;
    color: #000000;
  }

  &__edit {
    display: none;

    width: 24px;
    height: 24px;

    input {
      cursor: pointer;
      width: 100%;
      height: 100%;
      border: 1px solid #000000;
      border-radius: 4px;
    }
    @include sm {
      display: block;
    }
  }
}
