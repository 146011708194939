@import '../../styles/mixins';

.mainPage {
  display: flex;
  flex-direction: column;
  margin: 0 10px 0 10px;
  padding: 17px;
  background: #f8fdff;
  border-radius: 20px;

  @include sm {
    padding: 20px;
  }
  @include lg {
    padding: 36px;
  }
}
.mainPage__tabs {
  transition: opacity 0.1s ease-in-out; /* Добавляем плавную анимацию перехода */
  height: 100%;
}

.mainPage__tabs.hidden {
  height: 0 !important;
  opacity: 0; /* Устанавливаем прозрачность 0 */
  pointer-events: none; /* Отключаем события мыши */
}
