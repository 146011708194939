@import "../../styles/mixins.scss";
.btn-title {
  color: #fff;
  font-style: normal;
  line-height: 110%; /* 17.6px */
  text-transform: uppercase;
}
.btn-input{
  display: flex;
  height: 40px;
  border: none;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 110px;

  transition: opacity 0.2s ease-in-out;

  @include sm {
    width: 100%;
    max-width: 86px;
    height: 40px;
  }
  @include md {
    &:hover {
      opacity: .85;
    }
  }
}