@import "../../styles/mixins.scss";

.animation__container {
   position: fixed;
   z-index: 1000;
   left: 0;
   top: 0;
   right: 0;
   bottom: 0;

   height: 100vh;
   opacity: 0;

   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   padding: 0 20px;

   @media (min-width: 600px) {
      padding: 0 30px;
   }
   @include lg {
      padding: 0 100px;
   }

   background-color: #fff;
   color: black;
}

.animation__logo {
   // position: absolute;
   // top: 30%;
   // left: 50%;
   width: 150px;
   height: 30px;

   @media (min-width: 600px) {
      width: 180px;
      height: 40px;
   }
   @include lg {
      width: 350px;
      height: 60px;
      margin-bottom: 30px;
   }

   // transform: translateX(-50%);
}
.animation__text {
   max-width: 1000px;
   font-size: 20px;
   text-align: center;
   margin-bottom: 5px;
   line-height: 1.2;
   margin-top: 10px;
   margin-bottom: 15px;

   @include xs {
      font-size: 28px;
   }
   @media (min-width: 600px) {
      font-size: 35px;
      max-width: 600px;
   }
   @include sm {
      font-size: 40px;
   }
   @include lg {
      font-size: 64px;
      max-width: 1000px;
   }
}
.animation__subtext {
   font-size: 20px;
   text-align: center;
   font-weight: 600;
   line-height: 1.4;

   @include xs {
      font-size: 28px;
   }
   @media (min-width: 600px) {
      font-size: 35px;
   }
   @include sm {
      font-size: 40px;
   }
   @include lg {
      font-size: 64px;
   }
}
