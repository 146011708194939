@import "../../styles/mixins.scss";

.accordion{
  display:block;
}
.input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 10px;
  @include sm{
    gap: 14px;
  }
}
.item {
  width: 100%;
  overflow: hidden;
  &-label {
    display: flex;
    justify-content: space-between;
    padding: 20px 4px;
    cursor: pointer;
    @include sm{
      border-bottom: 1px solid #000000;
    }
    @include lg{
      padding: 26px 0;
    }
    h4{
      width: 80%;
    }

    /* Icon */
    &::after {
      content: "";
      width: 24px;
      height: 24px;
      background-image: url("../../assets/images/Plus.svg");
      background-color: black;
      background-position: center;
      border-radius: 17px;
      @include sm {
        width: 34px;
        height: 34px;
      }
    }
  }
  &-content {
    line-height: 120%;
    max-height: 0;
    transition: all .1s;
  }
}

// :checked
input:checked {
  + .item-label {
    &::after {
      background-image: url("../../assets/images/Minus.svg") !important;
    }
  }
  ~ .item-content {
    max-height: 100vh;
    padding: 0 0 16px 0;

    @include sm {
      padding: 16px 0;
    }
  }
}
