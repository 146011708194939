.custom-checkbox {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: transparent;
  border: 1px solid #000000;
  border-radius: 4px;
}
.custom-checkbox--active {
  background: #000;
}