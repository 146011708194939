@import '../../styles/mixins.scss';

.form {
  &__wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 16px 12px;
    @include xs {
      padding: 30px 20px;
    }
    @include sm {
      display: flex;
      flex-direction: row;
      padding: 26px 17px 30px 17px;
      align-items: center;
      gap: 20px;
    }
    @include md {
      max-width: 840px;
    }
    @include lg {
      max-width: 886px;
      padding: 14px 38px;
    }
  }

  &__title {
    margin-bottom: 14px;
    line-height: 120%;
    font-weight: 600;
    text-transform: uppercase;
    @include sm {
      margin-bottom: 0;
      padding-right: 20px;
      flex: 0 1 50%;
    }
  }

  &__partition {
    display: none;

    @include sm {
      display: block;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    gap: 14px;

    @include xs {
      flex: 0 1 50%;
      flex-direction: row;
      gap: 20px;
    }
    @include sm {
      padding-left: 32px;
    }
  }

  &__form-email {
    width: 100%;
    padding: 1px 6px;
    border: none;
    border-bottom: 1px solid #000000;
    background-color: transparent;
    font-size: 16px;
    outline: none;
    text-transform: uppercase;

    &::placeholder {
      color: #000000;
      text-transform: uppercase;
    }

    @include sm {
      width: 100%;
    }
  }
}
