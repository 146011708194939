.selectTicketTopic {
  &__container {
    position: relative;
  }

  &__body {
    cursor: pointer;
    position: relative;
    padding: 7px 6px 15px 6px;
    border-bottom: 1px solid #000000;
    margin-bottom: 44px;
  }

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 110%;
    text-transform: uppercase;
    color: #7d99a0;
  }

  &__svg {
    position: absolute;
    right: 11px;
    top: 8%;
  }

  &__topics-container {
    z-index: 999;
    position: absolute;
    top: -15%;
    left: 0;
    right: 0;
    border: 1px solid #000000;
    border-radius: 16px;
    background: #fff;
    padding: 24px 0;
  }

  &__topics-list {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  &__topics-item {
    cursor: pointer;
    padding: 11.5px 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 110%;
    text-transform: uppercase;
    color: #000000;
    transition: background 0.2s ease-in-out;

    &:hover {
      background: #7d99a022;
    }
  }
}
