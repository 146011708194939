@import "../../mixins.scss";

.accordion-container{
  display:block;
  @include sm{
    display: none;
  }
}
.input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

/* Accordion styles */
.tabs {
  border-radius: 8px;
  overflow: hidden;
}
.tab {
  width: 100%;
  color: white;
  overflow: hidden;
  &-label {
    display: flex;
    justify-content: space-between;
    color: black;
    padding: 16px 24px;
    font-weight: bold;
    cursor: pointer;
    /* Icon */
    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      text-align: center;
      transition: all .1s;
    }
  }
  &-content {
    max-height: 0;
    padding-left:24px;
    color: black;
    background: white;
    transition: all .1s;

    ul{
      list-style: none;
    }
    li{
      margin-bottom: 23px;
    }
    a{
      cursor:pointer;
      text-transform: uppercase;
      font-size: 14px;
    }
    &__more{
      color:#7D99A0;
      text-transform: none !important;
      text-decoration-line: underline;
    }
  }
  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    cursor: pointer;
  }
}

// :checked
input:checked {
  + .tab-label {
    &::after {
      transform: rotate(90deg);
    }
  }
  ~ .tab-content {
    max-height: 100vh;
    padding: 24px;
  }
}
