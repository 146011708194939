@mixin xs {
   @media (min-width: 390px) {
      @content;
   }
}
@mixin sm {
   @media (min-width: 834px) {
      @content;
   }
}
@mixin md {
   @media (min-width: 1280px) {
      @content;
   }
}
@mixin lg {
   @media (min-width: 1440px) {
      @content;
   }
}
@mixin xl {
   @media (min-width: 1728px) {
      @content;
   }
}

@mixin xxl {
   @media (min-width: 1920px) {
      @content;
   }
}

