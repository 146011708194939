@import "../../styles/mixins.scss";

.faq {
  &__body {
    display: flex;
    flex-direction: column;
    gap: 14px;
    @include sm{
      padding: 0 13px;
    }
    @include md{
      padding-left: 360px;
      padding-right: 90px;
    }
    @include lg{
      padding-left: 460px;
      padding-right: 100px;
    }
    @include xl{
      padding-left: 560px;
    }
    @include xxl{
      padding-left: 730px;
    }

    &-caption{
      font-weight: bold;
      padding: 14px 0;
      h1
      {
        font-weight: bold;
      }
      @include sm{
        padding: 52px 0;
      }
      @include lg{
        padding: 75px 0;
      }
      @include xl{
        padding: 90px 0;
      }
    }
  }
}
