@import "../../styles/mixins.scss";

.commission {
  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 100px;
    @include md {
      flex-direction: row;
      & > :first-child {
        order: 2;
      }
      & > :nth-child(2) {
        order: 1;
      }
    }
  }
  &__caption {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 75px;
    @include sm {
      width: 90%;
    }
    @include md {
      margin-top: 100px;
      width: 65%;
    }
  }
  &__title {
    display: flex;
    flex-direction: column;
    gap: 22px;
    h3 {
      line-height: 1;
      font-weight: 600;
    }
  }
  &__action {
    display: flex;
    flex-direction: column;
    @include sm {
      flex-direction: row;
    }
    &-text {
      display: flex;
      align-self: start;
      @include sm {
        width: 50%;
      }
    }
    &__btn {
      margin-top: 20px;
      @include sm {
        margin-top: 0;
      }
      button {
        width: 100%;
        @include sm {
          width: 292px;
          max-width: 292px;
        }
      }
    }
  }

  &__images {
    display: flex;
    justify-content: center;
    width: 100%;
    @include md {
      width: 50%;
    }
    margin: 0 auto;
    .img {
      width: 211px;
      height: 281px;
      @include xs {
        width: 65%;
        height: 65%;
      }
      @include sm {
        width: 423px;
        height: 562px;
      }
      @include lg {
        width: 90%;
        height: 90%;
      }
      @include xxl {
        width: 87%;
        height: 90%;
      }
    }
  }
}
