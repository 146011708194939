@import '../../styles/mixins.scss';

.hero {
  display: flex;
  flex-direction: column;
  padding-top: 24px;

  &__container {
    position: relative;
    width: 100%;
    height: 50vw;
    min-height: 210px;

    @include xs {
      height: 60vw;
    }

    @include sm {
      height: 40vh;
    }

    @include md {
      height: 45vh;
    }

    @include lg {
      height: 55vh;
    }
    @include xl {
      height: 65vh;
    }

    img {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      object-fit: cover;
      object-position: 80% 0%;

      @include sm {
        border-radius: 26px;
        object-position: 100% 0%;
      }
    }
  }

  .text-hero {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;

    @include sm {
      flex-direction: row;
      gap: 40px;
    }

    &__description {
      display: flex;
      width: 100%;
      margin-top: 32px;
      flex-direction: column;
      gap: 12px;

      @include sm {
        width: 60%;
      }
      @include md {
        padding: 25px 12px;
      }
      @include lg {
        margin-top: 12px;
        padding: 40px 20px;
      }

      h1 {
        margin-bottom: 12px;

        @include md {
          margin-bottom: 24px;
        }
      }

      span {
        padding-left: 10px;

        @include sm {
          width: 70%;
          padding-left: 20px;
        }
      }
    }

    &__description-bullets {
      display: flex;

      span {
        @include xs {
          width: 80%;
        }
      }
    }

    &__sale {
      display: flex;
      width: 100%;
      margin-top: 32px;
      padding: 16px 12px;
      border-radius: 16px;
      background: radial-gradient(
          109.49% 665.18% at -1.42% -146.15%,
          rgb(203, 244, 255) 13.48%,
          rgb(157, 208, 255) 28.68%,
          rgba(105, 255, 228, 0.608) 45.53%,
          rgba(105, 210, 255, 0) 66.77%
        ),
        rgb(203, 244, 255);
      flex-direction: column;
      gap: 25px;
      @include xs {
        padding: 30px 20px;
      }
      @media screen and (min-width: 630px) {
        padding: 30px 20px;
        flex-direction: row;
      }
      @include sm {
        width: 380px;
        margin-top: 12px;
        border-radius: 26px;
        background: rgb(255, 255, 255);
        gap: 10px;
        flex-direction: column;
      }
    }

    &__sale-time {
      display: flex;
      margin-top: 8px;
      -webkit-box-align: center;
      align-items: center;
      gap: 4px;
      @media screen and (min-width: 630px) {
        margin-top: 16px;
        margin-bottom: 30px;
      }

      span {
        display: flex;
        box-sizing: border-box;
        font-weight: 400;
        line-height: 120%;
        text-transform: uppercase;
      }
    }

    &__sale-break {
      width: 1px;
      height: 17px;
      margin-right: 10px;
      margin-left: 10px;
      background-color: rgb(0, 0, 0);
    }

    &__sale-join {
      display: flex;
      flex-direction: column;
      gap: 10px;

      span {
        line-height: 120%;
      }
    }

    &__sale-join-mail {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @include xs {
        flex-direction: row;
      }
      @include sm {
        gap: 20px;
      }
    }

    &__sale-join-mail-input {
      display: inline-flex;
      position: relative;
      width: 100%;

      input {
        width: 100%;
        padding: 8px 6px;
        background-color: transparent;
        color: rgb(0, 0, 0);
        border: none;
        outline: none;
        border-bottom: 1px solid #000000;

        &::placeholder {
          color: #000000;
        }
      }
    }
  }
}
.countdown-form {
  margin-top: 40px;
}
