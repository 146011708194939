@import "../../mixins";

.main-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  background-color: white;
  border-radius: 26px;

  @include md {
    flex-wrap: nowrap;
    gap: 18px;
  }
  @include lg {
    gap: 40px;
  }
  @include xl {
    gap: 70px;
  }
}

.main-tools-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 16px;
  margin-bottom: 20px;
  @include md {
    flex-direction: row;
    justify-content: space-between;
  }
  @include lg {
  }
}

.buttons-main {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 24px;
    gap: 15px;
    background: #ffffff;
    border-radius: 26px 26px 0 0;

    @include sm {
      flex-direction: row;
      align-items: center;
    }
    @include md {
      height: fit-content;
      width: 100%;
      min-width: 245px;
      border-radius: 0;
      background: none;
    }
    @include lg {
      min-width: 300px;
    }
    @media (min-width: 1512px) {
      min-width: 330px;
    }
  }
}

.search-container {
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid;
  margin: 16px 24px;
  @include md {
    width: 100%;
  }

  //.search-icon {
  //  font-size: 18px;
  //  color: #666;
  //}

  .search-input {
    border: none;
    outline: none;
    font-size: 18px;
    margin-left: 5px;
    width: 100%;
    text-transform: uppercase;

    &::placeholder {
      color: #999;
    }
  }
}

.articles__list {
  display: none;

  @include sm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
  @include lg {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
}

.articles__list-article {
  padding: 16px 24px;

  .article-label {
    display: block;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 25px;
  }

  .article-content {
    ul {
      list-style-type: none;
      li {
        margin-bottom: 20px;
        a {
          cursor: pointer;
          text-transform: uppercase;
          font-size: 14px;
        }
      }
    }

    &__more {
      margin-top: 10px;
      color: #7d99a0;
      text-transform: none !important;
      text-decoration-line: underline;
    }
  }
}