.tips__container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  align-self: self-start;
  gap: 24px;
}
.tips__title {
  margin-bottom: 12px;
  @media (min-width: 1512px) {
    margin-bottom: 24px;
  }
}
