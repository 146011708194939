@import "../../styles/mixins.scss";

.countdown {
    display: flex;
    width: 100%;
    margin-top: 32px;
    padding: 30px 20px;
    border-radius: 16px;
    background: radial-gradient(
                    109.49% 665.18% at -1.42% -146.15%,
                    rgb(203, 244, 255) 13.48%,
                    rgb(157, 208, 255) 28.68%,
                    rgba(105, 255, 228, 0.608) 45.53%,
                    rgba(105, 210, 255, 0) 66.77%
    ),
    rgb(203, 244, 255);
    flex-direction: column;
    gap: 25px;
    @media screen and (min-width: 630px) {
      flex-direction: row;
    }
    @include sm {
      flex-direction: column;
      gap: 10px;
      margin-top: 12px;
      padding: 0;
      border-radius: 26px;
      background: none;
    }

  &__time {
    display: flex;
    margin-top: 8px;
    -webkit-box-align: center;
    align-items: center;
    gap: 4px;
    @media screen and (min-width: 630px) {
      margin-top: 16px;
      margin-bottom: 30px;
    }

    span {
      display: flex;
      box-sizing: border-box;
      line-height: 120%;
    }
  }
  &__break {
    width: 1px;
    height: 17px;
    margin-right: 10px;
    margin-left: 10px;
    background-color: rgb(0, 0, 0);
  }

  &__join {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 426px;
    line-height: 120%;
  }

  &__join-mail {
    display: flex;
    flex-direction: column;
    gap: 10px;

    @include sm {
      justify-content: space-between;
      flex-direction: row;
      gap: 20px;
    }
  }

  &__join-mail-input {
    display: inline-flex;
    position: relative;

    @include sm {
      flex: 1 1 100%;
    }

    input {
      width: 100%;
      padding: 8px 6px;
      background-color: transparent;
      color: rgb(0, 0, 0);
      border: none;
      outline: none;
      border-bottom: 1px solid #000000;

      &::placeholder {
        color: #000000;
      }
    }
  }
}
