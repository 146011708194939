@import '../../styles/mixins.scss';

.blockchain {
  &__content {
    display: flex;
    flex-direction: column;
    padding: 230px 0 20px 0;

    @media (min-width: 600px) {
      max-width: 560px;
    }

    @include sm {
      max-width: 749px;
      padding: 430px 0 30px 0;
    }

    @include md {
      max-width: 649px;
      padding: 0px 0 108px 0;
    }
    @include lg {
      max-width: 728px;
    }
    @include xl {
      max-width: 802px;
      padding: 150px 0 230px 0;
    }

    ul {
      line-height: 120%;
      margin-left: 20px;
      list-style-type: disc;
      @include sm {
        margin-left: 35px;
      }
    }
  }

  &__title {
    margin-bottom: 56px;
    line-height: 120%;

    @include md {
      margin-bottom: 40px;
    }
  }

  &__subtitle {
    margin-bottom: 10px;
    line-height: 120%;

    @include sm {
      margin-bottom: 13px;
    }
  }
}
.main-img {
  background-image: url('../../assets/blockchain/main-image.png');
  background-size: 322px 210px;
  background-position: 30% 2%;
  background-repeat: no-repeat;

  @include sm {
    background-size: 645px 391px;
  }
  @include md {
    background-position: 95% 55%;
  }
  @include lg {
    background-size: 783px 474px;
  }
  @include xl {
    background-size: 1238px 750px;
  }
}
