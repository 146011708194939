@import '../../styles/mixins.scss';

.video {
  &__title {
    margin-bottom: 13px;

    @include xs {
      margin-bottom: 10px;
    }
    @media (min-width: 600px) {
      max-width: 560px;
    }
    @include sm {
      max-width: 716px;
      margin-bottom: 16px;
    }
    @include md {
      max-width: 1000px;
    }
    @include lg {
      max-width: 1240px;
    }
    @include xxl {
      max-width: 1500px;
    }
  }

  &__subtitle {
    margin-bottom: 58px;
    line-height: 120%;

    @media (min-width: 600px) {
      max-width: 560px;
    }
    @include sm {
      max-width: 716px;
      margin-bottom: 80px;
    }
    @include md {
      margin-bottom: 50px;
    }
  }

  &__row {
    display: flex;
    flex-direction: column;
    gap: 50px;
    @include sm {
      gap: 87px;
    }

    @include md {
      flex-direction: row;
      gap: 41px;
    }
  }
}
.item-video {
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include xs {
    gap: 16px;
  }
  @include sm {
    padding-bottom: 49px;
    border-bottom: 1px solid #000000;
    flex-direction: row-reverse;
  }
  @include md {
    flex-direction: column;
    gap: 24px;
    padding-bottom: 28px;
    flex: 1 0 48%;
  }

  @include xl {
    padding-bottom: 36px;
  }

  &__video {
    position: relative;
    height: 156px;
    border-radius: 16px;
    background-repeat: no-repeat;

    background-position: 50%;
    background-size: cover;
    cursor: pointer;

    &:hover > &-playBtn path {
      fill: white;
    }
    &:hover > &-playBtn rect {
      fill: black;
    }

    &-1 {
      position: relative;
      background-image: url('../../assets/video/video-1.png');
      &-logo {
        position: absolute;
        top: 5%;
        left: 3%;
      }
      @include lg {
        background-image: url('../../assets/video/video-1-1440.png');
      }
    }
    &-2 {
      background-image: url('../../assets/video/video-2.png');
      @include lg {
        background-image: url('../../assets/video/video-2-1440.png');
      }
    }
    &-playBtn {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      path {
        transition: fill 0.3s ease-in-out;
      }
      rect {
        transition: fill 0.3s ease-in-out;
      }
    }

    @media (min-width: 600px) {
      height: 256px;
    }
    @include sm {
      width: 100%;
      max-width: 420px;
      height: 218px;
    }
    @include md {
      max-width: 100%;
      height: 300px;
    }
    @include xl {
      height: 426px;
    }
    @include xxl {
      height: 441px;
    }
  }

  &__body {
    @include sm {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      justify-content: space-between;
    }
    @include md {
      gap: 18px;
    }
  }

  &__body-title {
    margin-bottom: 24px;
    @include xs {
      width: 60%;
    }
    @include sm {
      width: 100%;
      max-width: 650px;
      margin-bottom: 0;
      padding-top: 10px;
    }
    @include md {
      max-width: 640px;
    }
  }

  &__body-subtitle {
    line-height: 110%;
    text-decoration: underline;
    text-transform: uppercase;
    cursor: pointer;
  }
}
