.support-feedback-wrapper {
  position: relative;
}

.support-feedback-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 13px;

  @media (min-width: 834px) {
    padding: 20px 40px;
  }
  background: #f8fdff;
}

.animation-scale {
  @media (min-width: 834px) {
    transition: transform 0.2s ease-in-out;

    &:hover {
      transform: scale(1.03);
    }
  }
}
