@import '../../../../mixins.scss';

.support-attachBtn {
  &__container {
    display: flex;
    flex-direction: column;
    gap: 7px;
  }

  &__label {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 18px;
    background: #fff;
    border: 1px solid #000000;
    border-radius: 10px;
  }

  &__label-text {
    margin-top: 3px;
    font-size: 16px;
    text-transform: uppercase;
    color: #000000;
  }
  &__label-svg {
    max-height: 24px;
  }

  &__types-container {
    display: flex;
    gap: 11px;
    justify-content: center;
    align-items: center;

    @include sm {
      flex-direction: column;
      gap: 6px;
      align-items: start;
    }
  }

  &__types {
    font-size: 10px;
    line-height: 110%;
    color: #7d99a0;
  }

  &__maxSize {
    font-size: 10px;
    line-height: 110%;
    color: #7d99a0;
  }
}
