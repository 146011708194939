@import "../../../styles/mixins";

.solutions-img {
  display: flex;
  width: 100%;
  margin-top: 30px;
  justify-content: space-between;
  flex-direction: column;
  gap: 15px;

  @include xs {
    width: 100%;
  }
  @include sm {
    flex-direction: row;
  }
  @include md {
    gap: 30px;
  }
  }

  .solutions-img-icons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    @include xs {
      gap: 30px;
    }
    @include sm {
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: none;
    }

    &__item {
      display: flex;
      width: 50%;
      flex-direction: column;
      @include sm {

      }
      &-text {
        width: 50%;
        line-height: 120%;
      }

      &-icon {
        width: 50px;
        height: 50px;
        margin-right: 16px;
        margin-bottom: 15px;
        padding: 5px;
        @include sm {
          margin-bottom: 25px;
        }
      }
    }
  }

